<template>
    <div class="container">
        <div class="login">
            <div class="container">
                <h1>
                    <font-awesome-icon icon="user" class="nav-icon" /> Entrar
                </h1>
                <input type="text" name="user" placeholder="Email ou matricula" v-model="form.email">
                <input type="password" name="password" placeholder="Senha" v-model="form.password">
                <br>
                <input type="checkbox">
                <span>Lembrar me</span>
                <a href="#">Esqueceu a senha?</a>
                <button @click="login()">Entrar</button>
                <hr>
                <p>Ou</p>
                <hr>
                <ul>
                    <li>
                        <font-awesome-icon :icon="['fab', 'facebook-square']" size="4x" />
                    </li>
                    <li>
                        <font-awesome-icon :icon="['fab', 'google']" size="4x" />
                    </li>
                </ul>
                <div class="clearfix"></div>
                <span class="copyright">&copy;2019</span>
            </div>
        </div>
        <div class="register">
            <div class="container">
                <img src="../assets/logo.png" alt="employer_logo" class="employer-logo">
                <h2>Redação Online</h2>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import {API_BASE_URL} from '../constants/api'

// TODO login facebook
// TODO login google
export default {
    name: 'Login',
    data() {
        return {
            form: {
                email: '',
                password: '',
            }
        }
    },
    methods: {

        login() {

            axios.post(`${API_BASE_URL}/auth`, this.form).then(({ data }) => {
                localStorage.setItem('access_token', data.credentials.accessToken)
                localStorage.setItem('corrector', data.corrector)
                this.$router.push('dashboard')
            })
        }
    }
}
</script>

<style scoped>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        background:#e9ebee;
        display: flex;
        justify-content: center;
        align-items: center;
        height:100vh;
        font-family: sans-serif;
    }

    .container {
        width: 80%;
        margin: auto;
        padding: 20px;
        height:100%
    }

    .login, .register {
        width: 50%
    }

    .login {
        float:left;
        background-color: #fafafa;
        height: 100%;
        border-radius: 10px 0 0 10px;
        text-align: center;
        padding-top: 100px;
    }

    .login h1 {
        margin-bottom: 40px;
        font-size: 2.5em;
    }

    input[type="text"], input[type="email"], input[type="password"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 30px;
        border: none;
        background-color: #eeeeef;
    }

    input[type="checkbox"] {
        float: left;
        margin-right: 5px;
    }

    .login span {
        float: left
    }

    .login a {
        float: right;
        text-decoration: none;
        color: #000;
        transition: 0.3s all ease-in-out;
    }

    .login a:hover {
        color: #445894;font-weight: bold
    }

    .login button {
        width: 100%;
        margin: 30px 0;
        padding: 10px;
        border: none;
        background-color: #445894;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
    }

    .login button:hover {
        width:97%;
        font-size: 22px;
        border-radius: 5px;
    }

    .login hr {
        width: 30%;
        display: inline-block
    }

    .login p {
        display: inline-block;
        margin: 0px 10px 30px;
    }

    .login ul {
        list-style: none;
        margin-bottom:40px;  
    }

    .login ul li {
        display: inline-block;
        margin-right: 30px;
        cursor: pointer;
    }

    .login ul li:hover {
        opacity: 0.6
    }

    .login ul li:last-child {
        margin-right: 0
    }

    .login .copyright {
        display: inline-block;
        float: none;
    }

    .employer-logo {
        border-radius: 10px;
        box-shadow: 5px 10px 15px #000;
        width: 100%;
    }

    .register{
        float: right;
        background-image: linear-gradient(135deg,#445894 5%, #27396e 95%);
        height: 100%;
        color:#fff;
        border-radius:  0 10px 10px  0;
        text-align: center;
        padding: 100px 0;
    }

    .register h2 {
        margin: 30px 0;
        font-size: 50px;
        letter-spacing: 3px
    }

    .register p {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .register button {
        background-color: transparent;
        border: 1px solid #FFF;
        border-radius: 20px;
        padding: 10px 20px;
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        transition:0.2s all  ease-in-out;
    }

    .register button:hover {
        color: #3a4f80;
        background-color: #fff;
        cursor: pointer;
    }
</style>